function Interests(){
    return (
        <div className="interests">
        <h4 className="section-title"></h4>
        <p className="section-content">
        
        </p>
    </div>
    );
}
export default Interests;